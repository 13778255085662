import { Route, Routes, useLocation } from 'react-router-dom'
import Homepage from './home'
import ManageAccounts from './manage_accounts'
import SearchBar from './search'
import SelectContact from './select_contact'
import SendToCPIID from './send_by_cpi_id'
import SendToAddress from './send_by_ens.modal'
import Setting from './settings'
import SetupPasskey from './setup-passkey'
import SetupUsername from './setup-username'
import Transactions from './transactions'
import UpdateAccountInfo from './update_account_info'
import ChatScreen from './send/chat'
import SendMoney from './send'
import DepositScreen from './deposit'
import BalanceScreen from './balance'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Notifications from './notificaitons'
import { useEffect } from 'react'
const animationMap: { [key: string]: string } = {
  '/dashboard/settings': 'fadeIn',
  '/dashboard/search': 'fadeIn',
  '/dashboard/send-money': 'slideUp',
}
const oldPages = new Set()
let lastAnimation = ''
const getAnimationClass = (location: { pathname: string; key: string }) => {
  let animation
  const isBack = location.pathname === '/dashboard' || oldPages.has(location.key)
  if (lastAnimation === 'fadeIn' && isBack) {
    animation = 'fadeIn'
  } else if (isBack) {
    animation = 'slideInLeft'
  } else {
    animation = animationMap[location.pathname] || 'slideInRight'
  }
  lastAnimation = animation
  return animation
}
const Dashbaord = () => {
  let location = useLocation()

  useEffect(() => {
    oldPages.add(location.key) //track opend paths
  }, [location.key])
  return (
    <TransitionGroup component={null}>
      <CSSTransition key={location.key} classNames={getAnimationClass(location)} timeout={300}>
        <Routes location={location}>
          <Route index element={<Homepage />} />
          <Route path="/update-account/:accountId" element={<UpdateAccountInfo />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/setup-passkey" element={<SetupPasskey />} />
          <Route path="/setup-username" element={<SetupUsername />} />
          <Route path="/manage-accounts" element={<ManageAccounts />} />
          <Route path="/settings" element={<Setting />} />
          <Route path="/send-to-cpi" element={<SendToCPIID />} />
          <Route path="/send-to-address" element={<SendToAddress />} />
          <Route path="/select-contact" element={<SelectContact />} />
          <Route path="/deposit-funds" element={<DepositScreen />} />
          <Route path="/search" element={<SearchBar />} />
          <Route path="/send-money/*" element={<SendMoney />} />
          <Route path="/balance" element={<BalanceScreen />} />
          <Route path="/chat/:address" element={<ChatScreen />} />
          <Route path="/notifications" element={<Notifications />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}
export default Dashbaord
