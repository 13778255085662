import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import AppRouter from './app/index'
import PopupHandler, { popup } from './popup_handler'
import { useEffect, useState } from 'react'
import { platformAuthenticatorIsAvailable } from '@simplewebauthn/browser'
import { NativeBridge } from './utils/native_bridge'
import { getVersion } from './utils'
import DemoApp from './demo_app'
import { useQuery } from '@tanstack/react-query'
import { AuthService } from './api'
let MIN_IOS_VERSION_ALLOWED = '0.5.0'
let MIN_ANDROID_VERSION_ALLOWED = '0.5.0'
let MIN_ALLOWED_VERSION = getVersion((window as any).IS_IOS ? MIN_IOS_VERSION_ALLOWED : MIN_ANDROID_VERSION_ALLOWED)
//@ts-ignore
const handleNotification = (navigation: any, notification: string | null) => {
  if (!notification) return
  const { data } = JSON.parse(decodeURIComponent(atob(notification)))
  switch (data.type) {
    case 'TRANSACTION_OUT':
    case 'TRANSACTION_IN':
      return () =>
        popup.open('payment_receipt', {
          _id: data.orderId,
        })
  }
}
function App() {
  const user = useQuery(['user'], () => AuthService.Status())

  const [notification, setNotification] = useState<any>(null)
  useEffect(() => {
    if (!user || !user.data?._id || !notification) return
    // execute notification event
    notification()
  }, [user.data, notification])
  const navigation = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    /** register notification;
     * its kind of of queue but just stores one notification and waits for user to authenticate for execution */
    let query = new URLSearchParams(window.location.search)
    const notification = query.get('notification')
    if (notification) {
      /** notificaiton from url query */
      searchParams.delete('notification')
      setSearchParams(searchParams)
      setNotification(() => handleNotification(navigation, notification))
    }
    let brocker
    if (!NativeBridge.isBridge()) {
      brocker = navigator.serviceWorker.addEventListener('message', (event) => {
        /** if cray is running in a browser tab */
        const { type, notification } = event.data
        if (type === 'NOTIFICATION_CLICK') {
          setNotification(() => handleNotification(navigation, notification))
        }
      })
    } else {
      brocker = NativeBridge.addEventListener('NOTIFICATION_CLICK', (notification: any) => {
        /** notification from native bridge */
        setNotification(() => handleNotification(navigation, notification))
      })
    }
    brocker
  }, [])
  useEffect(() => {
    ;(async () => {
      const CURRENT_VERSION = getVersion((window as any).NATIVE_APP_VERSION || '')
      if (NativeBridge.isBridge()) {
        if ((window as any).CAN_USE_BIOMETRIC_AUTHENTICATION === false) {
          return popup.open('not_supported')
        }
        if (CURRENT_VERSION < MIN_ALLOWED_VERSION) {
          return popup.open('update_required_error')
        }
      } else {
        if (!(await platformAuthenticatorIsAvailable())) {
          popup.open('not_supported')
        }
      }
    })()
  }, [])
  let location = useLocation()
  ;(window as any).location_key = location.key
  const IS_DEMO_APP = import.meta.env.VITE_DEMO_HOST === window.location.origin
  if (IS_DEMO_APP)
    return (
      <>
        <DemoApp />
        <PopupHandler />
      </>
    )
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div id="app" className="  relative overflow-hidden w-full md:w-[384px] mx-auto h-screen md:h-[667px] shadow">
        <AppRouter />
        <PopupHandler />
      </div>
    </div>
  )
}

export default App
