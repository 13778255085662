import { useEffect, forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import PopupOverlay from '../popup_overlay'
import { ArrowLeft } from 'lucide-react'

export const Popup = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      onClickOutside,
      className = '',
      title,
      close,
      navClassName = '',
    }: {
      props?: any
      page?: Boolean
      children?: ReactNode | ReactNode[]
      close?: Function
      onClickOutside?: Function
      className?: string
      title?: string
      navClassName?: string
    },
    ref: any
  ) => {
    const closeModal = (e: any) => {
      if ((ref?.current && ref?.current?.children[1].contains(e.target)) || !document.body?.contains(e.target)) {
        return
      }

      if (onClickOutside) {
        onClickOutside()
      } else {
        // ref.current.children[1].classList.add("animate__modal__headShake")
        // setTimeout(() => {
        //     ref.current.children[1].classList.remove("animate__modal__headShake")
        // }, 400)
      }
    }
    useEffect(() => {
      setTimeout(() => {
        document.body.addEventListener('click', closeModal)
      }, 0)
      return function cleanup() {
        document.body.removeEventListener('click', closeModal)
      }
    }, [])
    const handleClose = () => {
      if (close) {
        close()
      }
    }
    return (
      <>
        <div ref={ref} className="popup-component ">
          <PopupOverlay />
          <div
            className={twMerge(
              'animate__animated flex overflow-scroll flex-col gap-4 absolute z-[20]  top-1/2 left-1/2 w-full md:w-auto -translate-x-1/2 -translate-y-1/2 shadow-[32px_32px_48px_0px_rgba(70,74,77,0.16)] px-4 py-8 bg-background text-secondary-foreground',
              className
            )}
          >
            {close ? (
              <div className={twMerge(`flex items-center gap-4 text-foreground`, navClassName)}>
                <button onClick={() => handleClose()} className="rounded-full p-1">
                  <ArrowLeft />
                </button>
                <p className="pl-4 text-lg font-bold">{title}</p>
              </div>
            ) : null}
            {children}
          </div>
        </div>
      </>
    )
  }
)
interface Props {
  props?: any
  page?: Boolean
  children?: ReactNode
  close?: Function
  onClickOutside?: Function
  className?: string
  title?: string
  navClassName?: string
}
