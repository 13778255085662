import { Button } from '@/cn/ui/button'
import { NETWORKS } from '@/constants'
import { popup } from '@/popup_handler'
import SuspenceLoader from '@/ui/suspence_loader'
import { get } from '@/utils'
import { useEffect, useState } from 'react'

const DemoApp = () => {
  const [loadingChains, setLoadingChains] = useState(false)
  useEffect(() => {
    setLoadingChains(true)
    get('/chains').then(({ result: { chains, activeChains: activeChainId } }) => {
      setLoadingChains(false)
      const activeChains = Object.keys(chains)
        .filter((chainId) => activeChainId.includes(Number(chainId)))
        .reduce((obj: any, chainId: any) => {
          obj[chainId] = chains[chainId]
          return obj
        }, {})
      // Object.assign(MAINNET_NETWORKS, mainnet)
      // Object.assign(TESTNET_NETWORKS, testnet)
      Object.assign(NETWORKS, activeChains)
    })
  }, [])
  if (loadingChains) {
    return (
      <SuspenceLoader
        children={
          <>
            <h2 className="">Hey there! 👋</h2>
            <h5 className="">We're just setting the stage.</h5>
            <p className=""> Please hold on while we get everything ready...</p>
          </>
        }
        loader={<div className="loader-line "></div>}
      />
    )
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-6">
      <div className="flex items-center gap-4">
        <div className="bg-green-500 rounded-full w-2 h-2 animate-pulse "></div>
        <span className="text-xl font-bold text-green-500">Minting is live!</span>
      </div>
      <h1 className="text-6xl  text-black font-bold mb-8 leading-tide">Demo DApp</h1>
      <img src="/cray-nft.webp" className="w-[300px] h-[300px] rounded-md shadow-lg" />
      <Button onClick={() => popup.open('demo_mint')}>Mint Now 0.00065 ETH</Button>
    </div>
  )
}
export default DemoApp
