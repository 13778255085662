import SuspenceLoader from '@/ui/suspence_loader'
import { AUTHENTICATION_STATE, NETWORKS, TOKENS, tokenAddressMap, tokenSymbolMap } from '@/constants'

import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Navigate, useLocation } from 'react-router'
import { get } from '@/utils'
import { IToken } from '@/interface'
const AUTHENTICATED_ROUTES = ['dashboard', 'onboarding']
const UNAUTHENTICATED_ROUTES = ['']
/**
 * any route that is not in the AUTHENTICATED_ROUTES or UNAUTHENTICATED_ROUTES is common route, if we need to override any sub route from them we need to add it into COMMON
 */
const COMMON: string[] = ['native-auth']
export function ModuleAuthenticator({ children, user }: { children: ReactNode; user: any }) {
  const [loadingChains, setLoadingChains] = useState(false)
  const [tokensLoading, setTokensLoading] = useState(false)
  const location = useLocation()
  const urlParams = new URLSearchParams(window.location.search)
  const notification = urlParams.get('notification')
  const isLoggedIn = user !== undefined && user !== null
  const isUserLoading = user === undefined
  useEffect(() => {
    setLoadingChains(true)
    setTokensLoading(true)
    get('/chains').then(({ result: { chains, activeChains: activeChainId } }) => {
      setLoadingChains(false)
      const activeChains = Object.keys(chains)
        .filter((chainId) => activeChainId.includes(Number(chainId)))
        .reduce((obj: any, chainId: any) => {
          obj[chainId] = chains[chainId]
          return obj
        }, {})
      // Object.assign(MAINNET_NETWORKS, mainnet)
      // Object.assign(TESTNET_NETWORKS, testnet)
      Object.assign(NETWORKS, activeChains)
    })
    get('/tokens').then(({ result }) => {
      setTokensLoading(false)
      Object.assign(TOKENS, result)
      Object.assign(
        tokenAddressMap,
        result.reduce((obj: any, prop: IToken) => {
          obj[prop.tokenAddress.toLowerCase()] = prop
          return obj
        }, {})
      )
      Object.assign(
        tokenSymbolMap,
        result.reduce((obj: { [key: string]: IToken }, prop: IToken) => {
          obj[`${prop.chainId}-${prop.symbol}`] = prop
          return obj
        }, {})
      )
    })
  }, [])
  useEffect(() => {
    if (!user) return
    ;(window as any).posthog.identify(
      user.email, // Replace 'distinct_id' with your user's unique identifier
      { email: user.email, name: user.name } // optional: set additional person properties
    )
  }, [isLoggedIn])
  const allowedUserType = useMemo(() => {
    const pathname = location.pathname.substr(1)
    const IS_AUTHENTICATED_ROUTE = AUTHENTICATED_ROUTES.some((route) => pathname.indexOf(route) === 0)
    const IS_UNAUTHENTICATED_ROUTE = UNAUTHENTICATED_ROUTES.some((route) => pathname.indexOf(route) === 0)
    const IS_COMMON_ROUTE = COMMON.some((route) => pathname.indexOf(route) === 0)
    if (IS_COMMON_ROUTE) {
      return 'ANY'
    } else if (IS_AUTHENTICATED_ROUTE) {
      return AUTHENTICATION_STATE.AUTHENTICATED
    } else if (IS_UNAUTHENTICATED_ROUTE) {
      return AUTHENTICATION_STATE.UNAUTHENTICATED
    } else {
      return 'ANY'
    }
  }, [location])

  const isAllowed = useMemo(() => {
    switch (allowedUserType) {
      case 'ANY':
        return true
      case AUTHENTICATION_STATE.AUTHENTICATED:
        return isUserLoading ? null : isLoggedIn === true
      case AUTHENTICATION_STATE.UNAUTHENTICATED:
        return isUserLoading ? null : isLoggedIn === false
    }
  }, [allowedUserType, isUserLoading, isLoggedIn])
  const redirectTo = useMemo(() => {
    if (!isAllowed) {
      if (!isLoggedIn) {
        return '/'
      } else {
        return `/dashboard/`
      }
    }
  }, [isAllowed, user, location])

  if (user === undefined || loadingChains || tokensLoading) {
    return (
      <SuspenceLoader
        children={
          <>
            <h2 className="">Hey there! 👋</h2>
            <h5 className="">We're just setting the stage.</h5>
            <p className=""> Please hold on while we get everything ready...</p>
          </>
        }
        loader={<div className="loader-line "></div>}
      />
    )
  }

  if (redirectTo !== undefined) {
    return <Navigate to={redirectTo + (notification ? `?notification=${notification}` : '')} />
  }
  return <>{children}</>
}
