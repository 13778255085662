import { Button } from '@/cn/ui/button'
import { PasskeyUtil } from '@/utils/passkey'
import { Navigate, useNavigate } from 'react-router-dom'

const Home = () => {
  const navigate = useNavigate()
  const passkeyUsers = PasskeyUtil.LoggedUser()
  let query = new URLSearchParams(window.location.search)
  let notification = query.get('notification')
  if (passkeyUsers.length) {
    return <Navigate to={'/auth' + (notification ? `?notification=${notification}` : '')} />
  }
  return (
    <div className="flex flex-col items-center justify-between h-full  relative text-foreground">
      <h3 className=" text-[28px] font-normal mt-20 shrink-0 mb-5">
        Welcome to <span className="text-primary">Cray!</span>
      </h3>
      <p className="text-center w-[343px] text-[22px] font-normal text-[hsla(0,0%,39%,1)] ">Simple stablecoin payments, so easy even your grandpa can use it.</p>
      <div className="flex-grow flex items-center">
        <img className=" mt-12  w-full px-3" src="/welcome_illustration.png" />
      </div>
      <div className="flex flex-col  w-full justify-between items-center p-4 gap-2 pb-8">
        <Button onClick={() => navigate('auth')} className=" w-full">
          Create Account
        </Button>
        <Button onClick={() => navigate('auth#signin')} className=" w-full">
          Login
        </Button>
      </div>
    </div>
  )
}
export default Home
