import { CHAIN_ID } from '@/constants'
import { ethers } from 'ethers'

export const rpcProvider: { [key: string]: ethers.JsonRpcApiProvider } = {
  [CHAIN_ID.MAINNET]: new ethers.JsonRpcProvider(import.meta.env.VITE_RPC_URL_MAINNET),
  [CHAIN_ID.POLYGON]: new ethers.JsonRpcProvider(import.meta.env.VITE_RPC_URL_POLYGON_POS),
  [CHAIN_ID.ARBITRUM]: new ethers.JsonRpcProvider(import.meta.env.VITE_RPC_URL_ARBITRUM),
  [CHAIN_ID.OPTIMISM]: new ethers.JsonRpcProvider(import.meta.env.VITE_RPC_URL_OPTIMISM),
  [CHAIN_ID.BASE]: new ethers.JsonRpcProvider(import.meta.env.VITE_RPC_URL_BASE),
  // // TESTNETS
  [CHAIN_ID.ETH_SEPOLIA]: new ethers.JsonRpcProvider(import.meta.env.VITE_RPC_URL_ETH_SEPOLIA),
  [CHAIN_ID.OP_SEPOLIA_CHAIN_ID]: new ethers.JsonRpcProvider(import.meta.env.VITE_RPC_URL_OP_SEPOLIA),
  [CHAIN_ID.ARB_SEPOLIA_CHAIN_ID]: new ethers.JsonRpcProvider(import.meta.env.VITE_RPC_URL_ARB_SEPOLIA),
  [CHAIN_ID.BASE_SEPOLIA_CHAIN_ID]: new ethers.JsonRpcProvider(import.meta.env.VITE_RPC_URL_BASE_SEPOLIA),
}
