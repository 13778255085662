import { chainLogos } from '@/config/chains_logo'
import { NETWORKS, TOKENS } from '@/constants'
import { IUser } from '@/interface'
import { popup } from '@/popup_handler'
import { useAppStore } from '@/store'
import { CopyButton, QR } from '@/ui'
import { TitleBar } from '@/ui/titlebar'
import { queryClient } from '@/utils'
import { ChevronDown } from 'lucide-react'
import { useState } from 'react'

const DepositScreen = () => {
  const user = (queryClient.getQueryData(['user']) || {}) as IUser
  const [currentNetwork, setCurrentChain] = useState(NETWORKS?.[user?.default_chain])
  const { wallet } = useAppStore((state) => state) as any

  const token = TOKENS.find((_) => _.chainId === currentNetwork.chainId)
  const handleChainSelect = () => {
    popup.open('select_chain', {
      currentChain: currentNetwork,
      setCurrentChain,
    })
  }

  return (
    <div className="flex flex-col gap-4 p-4 h-full w-full">
      <TitleBar title="Deposit" navClassName="sticky top-0" />
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2  px-4 cursor-pointer rounded-md ">
          <div className="flex items-center gap-2">
            <p>Network</p>
          </div>

          <button onClick={handleChainSelect} className="flex justify-between items-center p-2 cursor-pointer md:hover:bg-gray-100 active:bg-gray-100/80 border rounded-[16px] w-full">
            <div className="flex items-center gap-2">
              <img className="h-4 w-4" src={chainLogos[currentNetwork.chainId]} />
              <div className="flex flex-col gap-2">
                <p>{currentNetwork?.name}</p>
              </div>
            </div>
            <ChevronDown />
          </button>
        </div>
        <div className="flex flex-col gap-2 px-4 cursor-pointer rounded-md ">
          <div className="flex items-center gap-2">
            <p>Coin</p>
          </div>

          <button className="flex justify-between items-center p-2 cursor-pointer md:hover:bg-gray-100 active:bg-gray-100/80 border rounded-[16px] w-full">
            <div className="flex items-center gap-2">
              <img className="h-4 w-4" src={token?.icon} />
              <div className="flex flex-col gap-2">
                <p>{token?.name}</p>
              </div>
            </div>
            <ChevronDown />
          </button>
        </div>
      </div>
      <div>
        <p>Address</p>
        <div className="flex justify-between gap-4">
          <div className="flex flex-col">
            <p className="w-[214px]  break-words">{wallet?.address}</p>
            <CopyButton value={wallet?.address} />
          </div>
          <QR data={wallet?.address} className="w-[80px]" />
        </div>
      </div>
    </div>
  )
}
export default DepositScreen
