import { CHAIN_ID } from '@/constants'

export const chainLogos: { [key: string]: string } = {
  [CHAIN_ID.MAINNET]: '/icons/chains/ethereum-eth-logo.svg',
  [CHAIN_ID.POLYGON]: '/icons/chains/polygon-matic-logo.svg',
  [CHAIN_ID.ARBITRUM]: '/icons/chains/arbitrum-arb-logo.svg',
  [CHAIN_ID.OPTIMISM]: '/icons/chains/optimism-ethereum-op-logo.svg',
  [CHAIN_ID.BASE]: 'https://media.socket.tech/networks/base.svg',
  // // TESTNETS
  [CHAIN_ID.ETH_SEPOLIA]: '/icons/chains/ethereum-eth-logo.svg',
  [CHAIN_ID.OP_SEPOLIA_CHAIN_ID]: '/icons/chains/optimism-ethereum-op-logo.svg',
  [CHAIN_ID.ARB_SEPOLIA_CHAIN_ID]: '/icons/chains/arbitrum-arb-logo.svg',
  [CHAIN_ID.BASE_SEPOLIA_CHAIN_ID]: 'https://media.socket.tech/networks/base.svg',
}
