import { Button } from '@/cn/ui/button'
import { Input } from '@/cn/ui/input'
// import { useAppStore } from "@/store"

import { AuthService } from '@/api'
import { useToast } from '@/cn/ui/use-toast'
import { CHAIN_ID, NETWORKS } from '@/constants'
import { IChain } from '@/interface'
import { popup } from '@/popup_handler'
import { Loading } from '@/ui'
import { currencyMap, queryClient } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { ChevronDown } from 'lucide-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { chainLogos } from '@/config/chains_logo'
interface IUser {
  username?: string
  name?: string
  default_chain?: CHAIN_ID
  default_currency?: string
}

const SetupUsername = ({ username, name, default_chain, default_currency }: IUser) => {
  const [cpiIdSet, setCpiIdSet] = useState(false)
  const user = queryClient.getQueryData(['user']) as IUser
  const { toast } = useToast()
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IUser>({
    defaultValues: {
      username: username || user.username,
      name: name || user.name,
      default_chain: default_chain || user.default_chain,
      default_currency: default_currency,
    },
  })
  const mutation = useMutation((payload: IUser) => AuthService.UpdateProfile(payload), {
    onSuccess: () => {
      setCpiIdSet(true)
      toast({
        title: 'Username setup successfully',
      })
      setTimeout(() => {
        queryClient.invalidateQueries(['user'])
      }, 1000)
    },
    onError: (error: any) => {
      toast({
        title: error.message,
        variant: 'destructive',
      })
    },
  })
  const handleSave = (payload: IUser) => {
    mutation.mutate(payload)
  }
  const currentNetwork = NETWORKS[watch('default_chain') || 137]
  const currentCurrency = currencyMap[watch('default_currency') || 'USD']
  const handleChainSelect = () => {
    popup.open('select_chain', {
      currentChain: currentNetwork,
      setCurrentChain: (chain: IChain) => setValue('default_chain', chain.chainId),
    })
  }
  const handleCurrencySelect = () => {
    popup.open('select_currency', {
      currentCurrency: currentCurrency,
      setCurrentCurrency: (currency: any) => setValue('default_currency', currency.name),
    })
  }
  if (cpiIdSet) {
    return (
      <div className="h-full bg-bakground text-foreground flex items-center justify-center gap-4 flex-col">
        <h1>🎉</h1>
        <h3 className="text-center">CPI ID created successfully!</h3>
        <Loading className="fill-primary" />
      </div>
    )
  }
  const IS_INVLAID_PATTERN = (errors as any)?.username?.type === 'pattern'
  return (
    <div className="h-full bg-bakground text-foreground">
      <form onSubmit={handleSubmit(handleSave)} className=" py-4 flex justify-between flex-col gap-4 h-full  px-6">
        <div className="flex flex-grow flex-col gap-4 items-center justify-center ">
          <div className="flex flex-col gap-1">
            <h3>Setup a CPI ID</h3>
            {/* <p>What's the primary purpose of this account? This is just for you to see and won't be shared.</p> */}
          </div>
          <div className="w-full flex flex-col gap-6">
            <div className="grid w-full items-center gap-1.5">
              {/* <Label htmlFor="passphrase">CPI ID</Label> */}
              <div className="flex items-center gap-2 w-full">
                <Input
                  {...register('username', {
                    required: true,
                    pattern: /^[a-zA-Z0-9]+$/,
                  })}
                  placeholder="vitalik123"
                />
              </div>
              <span className="text-[10px] text-[#030303] ml-4">You will able to pay and receive payments using this CPI ID.</span>
              {IS_INVLAID_PATTERN ? <span></span> : null}
            </div>
            {!user.name ? (
              <div className="flex items-center gap-2 w-full">
                <Input
                  {...register('name', {
                    required: true,
                  })}
                  placeholder="Enter your name"
                  autoCapitalize="words"
                />
              </div>
            ) : null}
            <div className="w-full">
              <p className=" font-semibold"> Default Network</p>
              <button
                type="button"
                onClick={() => handleChainSelect()}
                className="flex justify-between items-center p-2 cursor-pointer hover:bg-gray-100 active:bg-gray-100/80 border rounded-[16px] w-full"
              >
                <div className="flex items-center gap-2">
                  <img className="h-4 w-4" src={chainLogos[currentNetwork?.chainId]} />
                  <div className="flex flex-col gap-2">
                    <p>{currentNetwork?.name}</p>
                  </div>
                </div>
                <ChevronDown />
              </button>
            </div>
            <div className="w-full">
              <p className=" font-semibold"> Default Currency</p>
              <button
                type="button"
                onClick={() => handleCurrencySelect()}
                className="flex justify-between items-center p-2 cursor-pointer hover:bg-gray-100 active:bg-gray-100/80 border rounded-[16px] w-full"
              >
                <div className="flex items-center gap-2">
                  <span>{currentCurrency?.symbol}</span>
                  <div className="flex flex-col gap-2">
                    <p>{currentCurrency?.name}</p>
                  </div>
                </div>
                <ChevronDown />
              </button>
            </div>
          </div>
          <Button loading={mutation.isLoading} variant="default" className="w-full ">
            Continue
          </Button>
        </div>
      </form>
    </div>
  )
}
export default SetupUsername
